import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {checkAuth} from '../utils/authUtils';

const PrivateRoute = () => {

  const isAuthenticated = checkAuth(); // Check if the user is authenticated

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
};

export default PrivateRoute;