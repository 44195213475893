import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAuth from '../api/apiAuth';
import {getAuth, delAuth} from '../utils/authUtils';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      const authData = getAuth();
      if(authData){
        try{
          const res = await apiAuth.delete('/logout', {
            data: {
              token: authData.refresh.token
            }
          });

          if(res.data.status === "success"){
            delAuth();
            navigate('/');
          }else{
            throw new Error("Logout failed from server");
          }
          
        }catch (error){
          console.error("Logout failed: ", error);
        }
      }else {
        navigate('/');
      }
    }
logout();

}, [navigate]);
// Optional: Render a message or loader while logging out
return <div>Logging out...</div>;
};

export default Logout;