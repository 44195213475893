import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import {postToPlatform, updatePost} from '../../utils/postUtils';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FaTrash } from 'react-icons/fa';

function CreatePost({ editPost }) {
  const [platform, setPlatform] = useState('linkedin');
  const [content, setContent] = useState('');
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (editPost) {
      setIsEditing(true);
      setPlatform(editPost.platform);
      setContent(editPost.content);
      setScheduleDate(new Date(editPost.scheduledTime)); // Convert string back to Date object
    } else {
      setIsEditing(false);
      setPlatform('linkedin');
      setContent('');
      setScheduleDate(new Date());
    }
  }, [editPost]);

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleDateChange = (date) => {
    setScheduleDate(date);
  };

  const handleDelete = async () => {
    if (!editPost || !editPost._id) return; // Check if there's an editPost and it has an ID
  
    try {
   
    } catch (error) {
      console.error('Error deleting the post:', error);
    }
  };
  

  const handleSubmit = async () => {
    const postData = {
        platform: platform,
        content: content,
        scheduledTime: scheduleDate.toISOString(),
      };
    try {
        let response;
        if(isEditing){
          response = await updatePost(editPost._id, postData);
        }else{
          response = await postToPlatform(postData);
        }

        if(response.status === "success"){
            console.log("Posted successfully:", response);
        }else {
            console.log("Posted failed:", response);
        }
        
    } catch (error) {
      console.error('Error posting data:', error);
      // Handle the error as needed
    }
  };

    return (
      <div className="flex justify-center items-start py-6 sm:py-12 px-10">
  <div className="p-6 w-full max-w-4xl mx-auto bg-white rounded-xl shadow-md">
    
            {/* Platform Selection */}
      <div className="mb-4">
        <label htmlFor="platform" className="block text-sm font-medium text-gray-700">Select Platform</label>
        <select
          id="platform"
          name="platform"
          value={platform}
          onChange={handlePlatformChange}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          <option value="linkedin">LinkedIn</option>
          {/* Additional platforms can be added here */}
        </select>
      </div>

      {/* Rich Text Editor */}
      <div className="mb-4">
        <label htmlFor="content" className="block text-sm font-medium text-gray-700">Your Post</label>
        <ReactQuill
          value={content}
          onChange={handleContentChange}
          className="bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      {/* Date-Time Picker */}
      <div className="mb-4">
        <label htmlFor="scheduleDate" className="block text-sm font-medium text-gray-700">Schedule Post</label>
        <DatePicker
          selected={scheduleDate}
          onChange={handleDateChange}
          showTimeSelect
          dateFormat="Pp"
          className="focus:border-sky-500 block w-full pl-3 pr-10 py-2 sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        type="button"
        className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 px-6 py-2 text-sm leading-5 rounded-md font-semibold text-white"
      >
        {isEditing ? 'Update' : 'Post'}
      </button>
      {isEditing && (
  <button
    onClick={handleDelete} // Define this function to handle the delete logic
    type="button"
    className="mt-4 w-full bg-red-500 hover:bg-red-600 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 px-6 py-2 text-sm leading-5 rounded-md font-semibold text-white"
  >
    <FaTrash className="inline mr-2" /> Delete
  </button>
)}

      </div>
    </div>
    );
}

export default CreatePost;