import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NotificationProvider } from "./contexts/NotificationContext";
import {LeftNav, PrivateRoute, Profile, Login, Integrations, Dashboard, LandingPage, Register, Settings, Logout, Post} from "./components/Components";

const WithLeftNav = ({ children }) => {
  return (
    <div className="flex flex-row">
      <LeftNav /> 
      <div className="flex-grow">
        {children}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <div className="bg-slate-100">
        <NotificationProvider>
          <Routes>
            {/* Routes without LeftNav */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            
            {/* Routes with LeftNav */}
            <Route path="/platforms" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Integrations />
                  </WithLeftNav>
                }
              />
            </Route>
            <Route path="/profile" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Profile />
                  </WithLeftNav>
                }
              />
            </Route>
            <Route path="/post" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Post />
                  </WithLeftNav>
                }
              />
            </Route>
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Dashboard />
                  </WithLeftNav>
                }
              />
            </Route>
            <Route path="/settings" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Settings />
                  </WithLeftNav>
                }
              />
            </Route>
            <Route path="/logout" element={<PrivateRoute />}>
              <Route index element={
                  <WithLeftNav>
                    <Logout />
                  </WithLeftNav>
                }
              />
            </Route>
          </Routes>
        </NotificationProvider>
      </div>
    </Router>
  );
};

export default App;
