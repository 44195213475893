import React from 'react';

const Notification = ({ show, message }) => {
  return (
    <div>
      {show && (
        <div className="notification">
          {message}
        </div>
      )}
    </div>
  );
};

export default Notification;