// Get the current date and time in a specific timezone
export const getCurrentDateTime = () => {
  const currentDate = new Date(); // Creates a new Date object with the current date and time
  return currentDate.toLocaleString('en-US');
};

// Format a given date into a specific format
export const formatDate = (date, format = 'yyyy-MM-dd') => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

// Other date/time utility functions...