import apiAuth from '../api/apiAuth';

const checkAuth = () => {
    const authData = JSON.parse(sessionStorage.getItem('userAuth'));
    if (!authData) {
      return false;
    }
  
    const refreshIssued = authData.refresh.issued;
  
    const twelveHours = 12 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const checkTime = new Date(refreshIssued).getTime();
    const timeDifference = Math.abs(currentTime - checkTime)
  
  
    return timeDifference <= twelveHours;
  };

  const isAccessTokenValid = () => {
    const authData = JSON.parse(sessionStorage.getItem('userAuth'));
    if (!authData) {
      console.log('is auth not valid')
      return false;
    }

    const refreshIssued = authData.access.issued;

    const oneHour = 1 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const checkTime = new Date(refreshIssued).getTime();
    const timeDifference = Math.abs(currentTime - checkTime)

    return timeDifference <= oneHour;
  }

  const getAuth = () => {
    const authData = JSON.parse(sessionStorage.getItem('userAuth'));
    if (!authData) {
      return false;
    }

    return authData;
  }

  const delAuth = () => {
    sessionStorage.removeItem('userAuth');
    sessionStorage.removeItem('userProfile');
  }

  const refreshAccessToken = () => {
    const authData = JSON.parse(sessionStorage.getItem('userAuth'));
    try{
      const res = apiAuth.post('/refresh-token', {
        data: {
          token: authData.refresh.token
        }});
  
        if(res.data.accessToken){
          sessionStorage.setItem('userAuth.access.token');
        }
    } catch (error){
      console.error(`Failed to refresh access token ${error}`);
    }
    
  }

  export {
    checkAuth,
    getAuth,
    delAuth,
    refreshAccessToken,
    isAccessTokenValid
  }