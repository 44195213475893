import React from 'react';
import {getUserProfile} from '../utils/userUtils';

const Profile = () => {
  return (
    <div className="w-full min-h-screen justify-center overflow-hidden py-6 sm:py-12 px-10">
      <h1>Dashboard Page</h1>
      <p>This is the dashboard landing page accessible to authenicated users.</p>
      <button onClick={getUserProfile} className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 px-6 py-2 text-sm leading-5 rounded-md font-semibold text-white">Get Profile</button>
      {/* Add more content and styling as needed */}
    </div>
  );
};

export default Profile;