import apiAuth from '../api/apiAuth';
import { isAccessTokenValid } from '../utils/authUtils';

const updatePost = async (postId, postData) => {
    if(isAccessTokenValid){
        try {
            const token = JSON.parse(sessionStorage.getItem('userAuth')).access.token;

            const response = await apiAuth.put(`/posts/${postId}`, postData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating post:', error);
            // Handle the error as needed
            throw error;
        }
    }else {
        console.log("Access token is not valid.");
        // Handle invalid token scenario
    }
}

const getPosts = async (page, pageSize) => {
    if(isAccessTokenValid){
        const token = JSON.parse(sessionStorage.getItem('userAuth')).access.token;
        const queryParams = new URLSearchParams({
            page: page,
            limit: pageSize
        });
        const response = await apiAuth.get(`/posts?${queryParams.toString()}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        return response;
    }else {
        console.log("Access token is not valid.");
        // Handle invalid token scenario
    }
}

const postToPlatform = async (postData) => {
    if (isAccessTokenValid()) {
        try {
            const token = JSON.parse(sessionStorage.getItem('userAuth')).access.token;

            const response = await apiAuth.post('/post', postData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error posting data:', error);
            // Handle the error as needed
            throw error;
        }
    } else {
        console.log("Access token is not valid.");
        // Handle invalid token scenario
    }
};

export {
    postToPlatform, 
    getPosts,
    updatePost
} ;
