import React from 'react';

const Profile = () => {
  return (
    <div className="w-full min-h-screen justify-center overflow-hidden py-6 sm:py-12 px-10">
      <h1>Profle Page</h1>
      <p>This is the profile landing page accessible to authenicated users.</p>
      {/* Add more content and styling as needed */}
    </div>
  );
};

export default Profile;
