import React from 'react';

const LandingPage = () => {
  return (
    <div>
      <h1>Welcome to Our Application</h1>
      <p>This is the public landing page accessible to everyone.</p>
      {/* Add more content and styling as needed */}
    </div>
  );
};

export default LandingPage;
