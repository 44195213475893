import React, { useState } from 'react';
import CreatePost from './createPost';
import ViewPosts from './viewPosts';

function Post() {

  // State to manage the currently edited post
  const [editPost, setEditPost] = useState(null);
  const clearEdit = () => setEditPost(null);

  return (
    <div className="flex flex-row min-h-screen"> {/* Main flex container */}
  <div className="flex-initial p-4 " style={{ flexGrow: 1, overflowY: 'auto' }}> {/* CreatePost at the top with reduced padding and no vertical scroll */}
  <CreatePost editPost={editPost} clearEdit={clearEdit} />
  </div>
  <div className="w-96 p-4" style={{ height: '100vh', overflowY: 'auto' }}> {/* Fixed width and viewport height for ViewPosts */}
  <ViewPosts setEditPost={setEditPost} />
  </div>
</div>

  );
}

export default Post;
