import apiAuth from '../api/apiAuth';
import {isAccessTokenValid} from '../utils/authUtils'

const getUserProfile = () =>{
    if(isAccessTokenValid){
        const token = JSON.parse(sessionStorage.getItem('userAuth')).access.token;
        console.log(token);
        apiAuth.get('/profile', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(!response.data.message){
                sessionStorage.setItem('userProfile', JSON.stringify(response.data.user));
            } else {
                console.error(`Unabled to get user profile ${response.data.message}`);
            }
        })
        .catch(error => {
            console.error(`Failed to get user profile ${error}`);
        });
    }
}

const updateLinkedInEmail = (email) => {
    if(isAccessTokenValid){
        const token = JSON.parse(sessionStorage.getItem('userAuth')).access.token;
        const res = apiAuth.get(`/linkedin/${email}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.data.status === 'success'){
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.error(`Failed to get user profile ${error}`);
            return false;
        });

        return res;
    }
}

const requestLinkedInAccess = () => {
    const clientId = "86og4rkipl9kfv"; // Your LinkedIn App Client ID
    const redirectUri = encodeURIComponent("https://auth.emetrixlabs.io/linkedin");
    const scope = encodeURIComponent("w_member_social,openid,profile,email");
    const responseType = "code";
  
    const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  
    console.log('redirection user to linkedin...bye...good luck!!!')
    window.location = authorizationUrl;
}

export {
    getUserProfile,
    updateLinkedInEmail,
    requestLinkedInAccess
}