import React, { useState } from 'react';
import { updateLinkedInEmail, requestLinkedInAccess } from '../utils/userUtils';
import { FaChevronLeft, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const Tile = ({ id, title, logo, bg, active, openModal }) => {

  return (
    <div className="flex items-center grow space-x-2 bg-slate-100 rounded-lg p-2">
      <div className={`flex-none text-3xl p-2 bg-${bg} text-white rounded-lg`}>
        { logo }
      </div>
      <div className="flex-1 font-medium">
        { title }
      </div>
      <div className="relative flex-none">
        <button type="submit" className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 px-4 py-2 text-sm leading-5 rounded-md font-semibold text-white" onClick={() => active && openModal(id)}>Add</button>
        
      </div>
    </div>
    
  );
};

const Modal = ({ showModal, closeModal, modalContent, selectedTileId }) => {
  const isLinkedInModal = selectedTileId === 1;
  const [email, setEmail] = useState('');

  const handleRequestAccess = () => {
    const updateEmail = updateLinkedInEmail(email);
    console.log('did it update', updateEmail);
    if(updateEmail){
      requestLinkedInAccess();
    }
  };

  return (
    <div
      className={`fixed inset-0 flex items-start justify-center pt-10 ${
        showModal ? "visible" : "hidden"
      }`}
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg p-8 z-10 relative">
        {/* X button at the top right corner */}
        <button
          onClick={closeModal}
          className="absolute top-0 right-0 mt-2 mr-2 text-lg font-semibold rounded-full h-8 w-8 flex items-center justify-center hover:bg-gray-200"
        >
          &times;
        </button>

        {/* Conditional content for LinkedIn */}
        {isLinkedInModal && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">LinkedIn Access</h2>
            <input
              type="email"
              value={email}
              placeholder="Enter your LinkedIn email"
              className="border p-2 rounded w-full mb-4"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleRequestAccess} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Request Access
            </button>
          </div>
        )}

        {/* For other tiles */}
        {!isLinkedInModal && <p>{modalContent}</p>}
      </div>
    </div>
  );
};


const IntegrationTiles = ({ onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTileId, setSelectedTileId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

    // Example data for tiles (Replace this with your data)
    const tilesData = [
      { id: 1, title: 'LinkedIn', logo: <FaLinkedin />, bg: '[#0A66C2]', content: 'Content for Tile 1', active: true },
      { id: 2, title: 'X', logo: <FaXTwitter />, bg: 'black',  content: 'Content for Tile 2', active: false },
      // Add more tile data as needed
    ];

  const openModal = (id) => {
    setShowModal(true);
    setSelectedTileId(id);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedTileId(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTilesData = tilesData.filter(tile =>
    tile.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full min-h-screen justify-center overflow-hidden p-2 space-y-6">
      <div className="flex flex-row space-x-4 items-center font-semibold">
        <button className="text-xl" onClick={onClose}><FaChevronLeft /></button><p>Add new platform</p>
      </div>
      <div>
      <input 
        type="text" 
        id="platformSearch" 
        value={searchTerm}
        onChange={handleSearchChange}
        className="mt-1 w-full px-4 py-4 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        placeholder="Search..." />
      </div>
      <div className="grid grid-cols-1 gap-2 ">
        {filteredTilesData.map((tile) => (
          <Tile key={tile.id} id={tile.id} title={tile.title} bg={tile.bg} logo={tile.logo} active={tile.active} openModal={openModal} />
        ))}
      </div>
      {selectedTileId && (
        <Modal
          showModal={showModal}
          closeModal={closeModal}
          modalContent={tilesData.find((tile) => tile.id === selectedTileId)?.content}
          selectedTileId={selectedTileId}
        />
      )}
    </div>
  );
};

export default IntegrationTiles;