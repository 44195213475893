import React, { useEffect, useState } from 'react';
import { getPosts } from '../../utils/postUtils';
import { FaEdit } from 'react-icons/fa';


function ViewPosts({ setEditPost }) {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    // Example API call to fetch posts
    const fetchPosts = async () => {
      try {
        const response = await getPosts(currentPage, pageSize);
        setPosts(
          response.data.data.sort(
            (a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime)
          )
        );
        const totalRecords = response.data.total; // Assuming total records count is returned
        setTotalPages(Math.ceil(totalRecords / pageSize));
      } catch (error) {
        console.error("Error fetching posts:", error);
        // Handle errors as needed
      }
    };

    fetchPosts();
  }, [currentPage]);

  const handlePrevious = () => {
    setCurrentPage((currentPage) => Math.max(1, currentPage - 1));
  };

  const handleNext = () => {
    setCurrentPage((currentPage) => Math.min(totalPages, currentPage + 1));
  };

  const handleEditPost = (post) => {
    setEditPost(post)
  };

  return (
<div className="fixed right-0 top-0 h-screen w-96 p-4 overflow-hidden flex flex-col items-center">
    <div className="h-[calc(100%-3rem)] overflow-y-auto w-full">
        {posts.map(post => (
            <div key={post._id} className="bg-white rounded-lg shadow-md overflow-hidden mb-4 p-4 w-80 h-auto mx-auto relative">
                <div className="absolute top-2 right-2">
                    <button onClick={() => handleEditPost(post)} className="text-blue-500 hover:text-blue-600">
                        <FaEdit className="w-6 h-6" />
                    </button>
                </div>
                <h3 className="text-lg font-semibold truncate">{post.title}</h3>
                <p className="text-gray-600 text-sm break-words">{post.content.substring(0, 500)}{post.content.length > 500 ? "..." : ""}</p>
                <p className="text-gray-500 text-xs">{new Date(post.scheduledTime).toLocaleString()}</p>
                <div className="flex space-x-2 mt-2">
                    {post.posted && <span className="bg-green-200 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Posted</span>}
                    <span className="bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{post.platform}</span>
                </div>
            </div>
        ))}
    </div>
    <div className="absolute bottom-4 left-4 right-4 flex justify-between items-center"> {/* Pagination controls with flexbox */}
    <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300" onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
    <span>Page {currentPage} of {totalPages}</span>
    <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
</div>

</div>
  );
}

export default ViewPosts;
