import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';
import { getCurrentDateTime } from '../utils/dateTimeUtils';
import Notification from './Notification';
import apiAuth from '../api/apiAuth';
import {getUserProfile} from '../utils/userUtils';
import bgImg from '../img/smai-beams.jpg';

const Login = () => {
  const navigate = useNavigate();
  const { showNotification, notificationMessage } = useNotification();

  const [loginFailed, setLoginFailed] = useState(false);
  const [userData, setUserData] = useState({
    emailAddress: '',
    password: ''
  });

  const handleChange = (e) => {
    setUserData({...userData, [e.target.id]: e.target.value});
    setLoginFailed(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginFailed(false);
    loginUser(userData);
  };

  const loginUser = async (userData) => {
    try {
      const response = await apiAuth.post('/login', userData);
      console.log(response);
      const currentDateTime = getCurrentDateTime();
      
      if(response.data.accessToken && response.data.refreshToken){
        console.log("Login successful:", response.data);
  
        const authData = {
          "access": {
            "token": response.data.accessToken,
            "issued": currentDateTime
          },
          "refresh": {
            "token": response.data.refreshToken,
            "issued": currentDateTime
          }
        }
        
        
        sessionStorage.setItem('userAuth', JSON.stringify(authData));
        getUserProfile();
        console.log(JSON.parse(sessionStorage.getItem('userAuth')));

        navigate("/dashboard");
      }else {
        console.log(`Login failed ${response.data}`);
        setLoginFailed(true);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginFailed(true);
    }
  }

  return (
    <div>
      <Notification show={showNotification} message={notificationMessage} />
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12 px-10">
        <img src={bgImg} alt="" className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" width="1308" />
        <div className="relative bg-white ring-1 ring-gray-900/5 shadow-md shadow-slate-200 rounded-md mx-auto md:w-96 w-full py-8 px-6">
          <div className="space-y-4">
            {/* <p className="font-semibold">Login</p> */}
            <form className="space-y-5" onSubmit={handleSubmit}>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700" htmlFor="username">Email</span>
                <input 
                  type="text" 
                  id="emailAddress" 
                  value={userData.emailAddress} 
                  className="mt-1 w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                  required
                  onChange={handleChange} />
              </label>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700" htmlFor='password'>Password</span>
                <input 
                  type="password" 
                  id="password" 
                  value={userData.password} 
                  className="mt-1 w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500" 
                  required
                  onChange={handleChange} />
              </label>
              <button type="submit" className="w-full bg-sky-500 hover:bg-sky-600 active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 px-6 py-2 text-sm leading-5 rounded-md font-semibold text-white">Login</button>
              {loginFailed && <p className="text-red-500 text-xs italic">Login Failed</p>}
            </form>
            <div className="pt-2">
              <p className="text-gray-900">Don't have an account?</p>
              <p>
                <Link to="/register" className="text-sky-500 hover:text-sky-600">Register here &rarr;</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;